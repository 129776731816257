






























































import Vue from "vue";
import { Me } from "@/repositores/user";
import { Login } from "@/repositores/user";
import {required} from "@/utils/validation"
export default Vue.extend({
  data() {
    return {
      loading: false,
      error:false,
      stores:[
       {name:'wave premium' , value: 0},
       {name:'wave' , value: 1},
       {name:'smile' , value: 2},
       {name:'aboali', value: 3}
      ],
      errors:[],
      rules :{
        username : [
            (value:string) => required(value) ,
        ],
        password : [
            (value:string) => required(value) ,
        ]
      },
      valid:true,
      form: {
        username: "",
        store:0,
        password: "",
      },
    };
  },

  methods: {
    login() {
       const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate()
        if(valid){
            this.loading = true;
            Login(this.form)
              .then((res:any) => {
                this.loading = false;
                this.$store.commit("ui/snackBar", {
                  active: true,
                  text: "logged_in",
                });
                localStorage.setItem('token' , res['token'])
                this.$router.push({ name: "Home" });

              })
              .catch((e) => {
                this.loading = false;
                this.error = true;
              });
          }
        }
  },
  created() {
    if (localStorage.getItem("token")) {
      Me()
        .then((res: any) => {
          this.$store.commit("user/email", res["email"]);
          localStorage.setItem('token' , res['token'])
          this.$router.push({ name: "Home" });
        })
        .catch((e: any) => {
          localStorage.removeItem("token");
        });
    }
  },
});
